import React from 'react'
import { Box, Group, Space, Text } from '@mantine/core'

import { ErrorBeacon, InlineLoader, useFormContext } from '@/components'
import { formatCurrency, formatDenom, formatLargeCurrency } from '@/wallet-utils'
import { useTokenValueInUsdQuery } from '@/queries'
import { useSelectedCoin } from '@/contexts/wallet'
import { useHostZoneQuery } from '../queries'
import { UnstakeFormType } from './useUnstakeForm'

interface UnstakeEstimateProps {
  hideDenomEstimate?: boolean
}

const UnstakeEstimate: React.FC<UnstakeEstimateProps> = ({ hideDenomEstimate }) => {
  const denom = useSelectedCoin()

  const { values } = useFormContext<UnstakeFormType>()

  const { data: hostZone, isPending: isHostZonePending, error: hostZoneError } = useHostZoneQuery()

  const { data: tokenValueInUsd, error: tokenValueInUsdError } = useTokenValueInUsdQuery(denom)

  if (hostZoneError) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', height: 22 }}>
        <ErrorBeacon label="Unable to fetch redemption rate at this time. Please try again." />
      </Box>
    )
  }

  if (isHostZonePending) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', height: 22 }}>
        <InlineLoader />
      </Box>
    )
  }

  const estimate = values.amount ? Number(values.amount) * Number(hostZone.redemption_rate) : 0

  return (
    <Box>
      {!hideDenomEstimate && (
        <>
          <Text
            weight={700}
            size="lg"
            align="right"
            sx={(t) => ({ color: !values.amount ? t.colors.gray[4] : t.colors.gray[9] })}
            inline>
            {formatLargeCurrency(format(estimate))} {denom}
          </Text>

          <Space h={4} />
        </>
      )}

      <Group spacing="xs" position="right" noWrap sx={{ height: 22 }}>
        <Text
          weight={500}
          align="right"
          size="sm"
          inline
          sx={(t) => ({ color: !values.amount ? t.colors.gray[4] : t.colors.gray[7] })}>
          <span>≈</span>
        </Text>

        {tokenValueInUsdError ? (
          <ErrorBeacon label={`Unable to get the dollar value of st${denom}. Please try again.`} />
        ) : tokenValueInUsd == null ? (
          <InlineLoader />
        ) : (
          <Text
            weight={500}
            align="right"
            size="sm"
            inline
            sx={(t) => ({ color: !values.amount ? t.colors.gray[4] : t.colors.gray[7] })}>
            {formatLargeCurrency(formatCurrency(estimate * tokenValueInUsd))}
          </Text>
        )}
      </Group>
    </Box>
  )
}

const format = (estimate: number) => {
  if (estimate >= 1) return formatDenom(estimate, 2)
  if (estimate >= 0.1) return formatDenom(estimate, 3)
  if (estimate >= 0.01) return formatDenom(estimate, 4)
  return formatDenom(estimate, 5)
}

export { UnstakeEstimate }
